@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@4alldigital/react-ui-kit-css--ottapp-one/fonts/'; @import "base-default-settings"; @import "ottapp-one-default-settings"; @import "base-settings"; @import "base-mixins"; @import "ottapp-one-settings";
.layout {
  // @include grid-default(1);
  // grid-auto-rows: min-content;
  padding-top: $default-spacing;
}

.menus {
  @include grid-default(2, false, $default-spacing, $default-spacing);

  &::before,
  &::after {
    display: none;
  }
}

// stylelint-disable-next-line selector-no-qualifying-type
a.menuLink {
  color: $colors-utility-white;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.footer {
  // background-color: $colors-primary-delta;
  color: $colors-utility-white;
  padding: $component-spacing 0 0;
  // text-align: center;
  position: relative;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-image: linear-gradient(
      to left,
      $colors-primary-beta,
      $colors-primary-gamma
    );
  }
}

.footerBottom {
  margin: $default-spacing 0;

  @include breakpoint($tablet) {
    @include grid-default(2);
  }
}

.footerMenu {
  @include grid-default(1, false, $default-spacing, $default-spacing);
  list-style: none;
  margin-bottom: $default-spacing;
  padding: 0;
}

.copyright {
  @include vr-plumbing-set($vr-fonts, small);

  color: $colors-utility-white;
  padding: 0 $default-spacing;
  text-align: center;
}

.logo {
  margin: $default-spacing 0;

  svg {
    fill: $colors-utility-black;
    width: 100px;
  }
}

.footerHeading {
  margin: 0;
  padding: 0;
}
