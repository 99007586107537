@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@4alldigital/react-ui-kit-css--ottapp-one/fonts/'; @import "base-default-settings"; @import "ottapp-one-default-settings"; @import "base-settings"; @import "base-mixins"; @import "ottapp-one-settings";
:global body {
  background-image: url(https://ost-static-assets.s3.eu-west-1.amazonaws.com/images/bg1-min.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.patterns {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0.5;
}

.sections {
  position: relative;
  z-index: 1;
}

.section {
  margin-bottom: $component-spacing;
  position: relative;
}

.intro {
  position: relative;
  // min-height: 750px;
}

.toastcontainer {
  background: transparent;
}

// stylelint-disable selector-class-pattern
:global .Toastify__toast-container {
  font-family: inherit;
}

:global .Toastify__toast-body {
  color: $colors-utility-white;
}

:global .Toastify__toast--alert,
:global .Toastify__toast--success,
:global .Toastify__toast--info,
:global .Toastify__toast--error {
  font-family: inherit;
}

:global .Toastify__toast--info {
  background: $colors-primary-alpha;
  font-family: inherit;
}

:global .Toastify__toast--warning {
  background: $colors-ui-zeta;
  font-family: inherit;
}

:global .Toastify__toast--error {
  background: $colors-alert-red;
  font-family: inherit;
}

// :global .root-content {
//   z-index: auto;
// }
:global .Toastify__toast-icon {
  svg {
    fill: $colors-utility-white;
  }
}

:global .Toastify__close-button {
  svg {
    fill: $colors-utility-white;
  }
}

:global .Toastify__progress-bar--info {
  background-color: $colors-utility-white;
}
