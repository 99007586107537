@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '../node_modules/@4alldigital/react-ui-kit-css--ottapp-one/fonts/'; @import "base-default-settings"; @import "ottapp-one-default-settings"; @import "base-settings"; @import "base-mixins"; @import "ottapp-one-settings";
/**
 *  Include base theme from node_modules folder.
 *  Use npm link to hook up local dev base-theme.
 */

@import '~brandstyles';

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

/* stylelint-disable */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: $colors-utility-white;
  box-shadow: 0 0 0px 1000px rgba($colors-primary-delta, 0.75) inset;
  -webkit-box-shadow: 0 0 0px 1000px rgba($colors-primary-delta, 0.75) inset;
  border: 1px solid rgba($colors-primary-delta, 0.75);
}
