@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@4alldigital/react-ui-kit-css--ottapp-one/fonts/'; @import "base-default-settings"; @import "ottapp-one-default-settings"; @import "base-settings"; @import "base-mixins"; @import "ottapp-one-settings";
.outer {
  position: relative;
  padding: $default-spacing;
  // margin: $component-spacing;

  @include breakpoint($tablet) {
    padding: $default-spacing $default-spacing $component-spacing
      $default-spacing;
  }

  @include breakpoint($desktop) {
    padding: $default-spacing $default-spacing $component-spacing * 3
      $default-spacing;
  }
}

.inner {
  @include grid-default(1);

  @include breakpoint($tablet) {
    @include grid-default(2);
  }
}

.imageOuter {
  margin: 0;
  position: relative;
  height: 300px;

  img {
    display: block;
  }

  @include breakpoint($tablet) {
    margin: 0 $element-spacing;
    height: 385px;
  }
}

.imageBoxed {
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 3px 5px 15px -5px rgba(0, 0, 0, 0.75);
}

.imageWrapper {
  position: absolute;
  width: 100%;
}

.image {
  border: 10px solid red;
}

.points {
  margin: $default-spacing 0;
}

.listItem {
  margin-bottom: $atom-spacing;
}

.ltr {
  text-align: left;
}

.rtl {
  @include breakpoint($tablet) {
    text-align: right;
  }

  @include breakpoint($tablet) {
    li {
      direction: rtl;
    }
  }
}
