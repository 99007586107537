@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@4alldigital/react-ui-kit-css--ottapp-one/fonts/'; @import "base-default-settings"; @import "ottapp-one-default-settings"; @import "base-settings"; @import "base-mixins"; @import "ottapp-one-settings";
@mixin header-align() {
  align-items: center;
  display: flex;
  justify-content: center;
}

$transition-duration: 150ms;
$transition-easing: ease-in-out;

.logo {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0;

  @include breakpoint($desktop) {
    justify-content: center;
  }

  svg {
    display: block;
    height: auto;
    padding: 0;
  }
}

.logoMobile {
  margin: auto;
  position: relative;

  svg {
    height: 48px;
    padding: 0;
  }
}

.account {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.headerWrapper {
  background-color: rgba($colors-utility-black, 0);
  padding: 0;
  position: relative;
  top: 0;
  transition: background-color 1s;
  width: 100%;
  z-index: 2;
}

.header {
  align-items: center;
  display: flex;
  justify-content: center;

  @include breakpoint($mobile-landscape, $below-tablet) {
    @include grid-default(150px 1fr 150px, true, $atom-spacing, $atom-spacing);
  }
}

.headerMobile {
  @include grid-default(100px 1fr 100px, true, $atom-spacing, $atom-spacing);
  height: 68px;

  svg {
    transition: all $transition-duration $transition-easing;
  }
}

.menu {
  align-items: center;
  display: flex;
}

.hamburger {
  padding: 0;
}

.hamburgerBars {
  > div > div {
    background-color: $colors-utility-white;

    &::after,
    &::before {
      background-color: $colors-utility-white;
    }
  }
}

.headerAlign {
  @include header-align();
}

.secondaryNavigation {
  margin-right: $atom-spacing;

  @include breakpoint($desktop) {
    display: block;
  }
}

header {
  position: relative;
  z-index: 1;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: colors-utility-black;
    background: linear-gradient(
      to bottom,
      rgba($colors-utility-black, 1) 0%,
      rgba($colors-utility-black, 0.6) 20%,
      rgba($colors-utility-black, 0.1) 70%,
      rgba($colors-utility-black, 0) 100%
    );
    z-index: -1;
    transition: all 1s;
    opacity: 0;
  }
}

.withBackground {
  header {
    &::before {
      opacity: 1;
    }
  }
}

.spacedHeader {
  height: 52px;

  @include breakpoint($tablet) {
    height: 62px;
  }

  @include breakpoint($desktop) {
    height: 92px;
  }
}
