@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@4alldigital/react-ui-kit-css--ottapp-one/fonts/'; @import "base-default-settings"; @import "ottapp-one-default-settings"; @import "base-settings"; @import "base-mixins"; @import "ottapp-one-settings";
.navItem {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

.navLink {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $colors-utility-white;

  &:hover {
    color: $colors-utility-white;
  }
}

.nav {
  align-items: center;
  display: flex;
  justify-content: space-evenly;
  margin: auto;
  max-width: 325px;
}
