@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@4alldigital/react-ui-kit-css--ottapp-one/fonts/'; @import "base-default-settings"; @import "ottapp-one-default-settings"; @import "base-settings"; @import "base-mixins"; @import "ottapp-one-settings";
.layout {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  //text-align: center;
}

.form {
  position: relative;

  @include breakpoint($tablet) {
    min-width: 450px;
  }
}

.heading {
  text-align: center;
}
