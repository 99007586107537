@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@4alldigital/react-ui-kit-css--ottapp-one/fonts/'; @import "base-default-settings"; @import "ottapp-one-default-settings"; @import "base-settings"; @import "base-mixins"; @import "ottapp-one-settings";
.page {
  position: relative;
}

.flush {
  position: relative;
}

.trouble {
  bottom: 0;
  position: absolute;
  right: $default-spacing;
  z-index: 99;

  a {
    color: $colors-utility-black;
  }
}

.banner {
  background-color: $colors-greys-alpha;
}

.centered {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}
