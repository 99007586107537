@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@4alldigital/react-ui-kit-css--ottapp-one/fonts/'; @import "base-default-settings"; @import "ottapp-one-default-settings"; @import "base-settings"; @import "base-mixins"; @import "ottapp-one-settings";
.layout {
  height: 100vh;
  // border: 1px solid red;

  @include breakpoint($tablet) {
    height: 100vh;
  }
}

.imageWrapper {
  max-width: 95%;
  margin: auto;
  position: relative;

  @include breakpoint($tablet) {
    max-width: 90%;
  }

  @include breakpoint($desktop) {
    max-width: 75%;
  }
}

.introImage {
  position: absolute;
  top: 0;
  left: 0;
}

.copyPosition {
  padding-top: 25vh;

  @include breakpoint($tablet) {
    padding-top: 35vh;
  }

  @include breakpoint($desktop) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 0;
  }
}

.taglinePosition {
  @include breakpoint($desktop) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.copyWrapper {
  position: relative;
  max-width: 95%;
  margin: auto;

  // @include breakpoint($tablet) {
  //   max-width: 85%;
  // }

  // &:before {
  //   content: '';
  //   width: 100%;
  //   height: 100%;
  //   background: radial-gradient(
  //     ellipse at center,
  //     rgba(0, 0, 0, 0.65),
  //     rgba(0, 0, 0, 0) 70%
  //   );
  //   position: absolute;
  //   left: 0;
  //   top: 0;
  //   z-index: 0;
  //   filter: blur(2px);
  // }
}

.copy {
  // border: 1px solid red;
  margin: auto;
  text-align: center;
  padding: 0 0 $component-spacing 0;
  position: relative;
  z-index: 1;
}

.heading {
  font-size: 50px;
  margin-bottom: 15px;
  letter-spacing: 3px;
  white-space: nowrap;

  @include breakpoint($tablet) {
    font-size: 80px;
  }

  @include breakpoint($desktop) {
    font-size: 95px;
    margin-bottom: 35px;
  }
}

.tagline {
  font-size: 35px;
  margin-bottom: 15px;
  letter-spacing: 3px;
  // white-space: nowrap;

  @include breakpoint($tablet) {
    font-size: 45px;
    white-space: nowrap;
  }

  @include breakpoint($desktop) {
    font-size: 55px;
    margin-bottom: 35px;
  }
}

.summary {
  font-size: 18px;

  @include breakpoint($tablet) {
    font-size: 23px;
  }

  @include breakpoint($desktop) {
    font-size: 28px;
  }
}
