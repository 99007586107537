@use "sass:color"; @use "sass:list"; @use "sass:math"; $icon-font-path: '~@4alldigital/react-ui-kit-css--ottapp-one/fonts/'; @import "base-default-settings"; @import "ottapp-one-default-settings"; @import "base-settings"; @import "base-mixins"; @import "ottapp-one-settings";
.root {
  position: relative;
  margin-top: 25vh;
  height: 75vh;

  svg {
    opacity: 0.2;
    filter: blur(4px);
  }
}

.alpha {
  opacity: 0.5 !important;
  filter: blur(4px) !important;

  * {
    fill: #8899aa !important;
  }
}

.beta {
  opacity: 0.7 !important;
  filter: blur(5px) !important;

  * {
    fill: #99aa88 !important;
  }
}

.gamma {
  opacity: 0.5 !important;
  filter: blur(4px) !important;

  * {
    fill: #aa8899 !important;
  }
}

.rect {
  // border: 5px solid red;
  width: 200px;
  height: 200px;
}

.shapeCluster01 {
  position: absolute;
  top: 0%;
  left: 40%;
  width: 8em;

  img {
    display: block;
  }

  .hemi-left {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
  }

  .hemi-right {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
  }
}

.shapeCluster02 {
  position: absolute;
  left: 5%;
  top: 25%;
  width: 18em;
  height: 30em;

  .triangle-big {
    position: absolute;
    left: 0;
    top: 20%;
    width: 60%;
  }

  .triangle-small {
    position: absolute;
    right: 0;
    top: 0;
    width: 60%;
  }

  .hemi {
    position: absolute;
    right: 0;
    bottom: 30%;
    width: 40%;
  }
}

.shapeCluster03 {
  position: absolute;
  top: 90%;
  left: 45%;
  width: 17em;
  height: 10em;

  .triangle-top {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .triangle-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .triangle-big {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 80%;
  }
}

.shapeCluster04 {
  position: absolute;
  top: 25%;
  right: 5%;
  width: 16em;

  .triangle {
    position: absolute;
    width: 66.666%;
    top: 0;
    left: 0;
  }
  .square {
    position: absolute;
    width: 66.666%;
    right: 0;
    top: 0;
  }
  .hemi-right {
    position: absolute;
    width: 33.333%;
    right: 0;
    top: 0;
  }
}
